import React,{ useState,useEffect } from "react";
import Input from "../../../../../components/Supervisor/input";
import classes from "../editor.module.scss";
import ImageUpload from "../../../Hazards/components/imageUpload";

import { connect } from "react-redux";

import {
    getAssesmentRequest
  } from "store/assesment/actions";

import {
    updateAssessmentReq,
    uploadInductionImageReq
  } from "store/supervisor/actions";
  
  import { createStructuredSelector } from "reselect";
  import {
    makeSelectAssesment,
    makeSelectLoading,
  } from "store/assesment/selector";

  import {
    makeSelectAssessmentDetails,
    makeSelectInductionImage,
    makeSelectInductionImageLoading,
    makeSelectInductionImageError,
    makeSelectInductionImageSuccess,
} from "store/supervisor/selector";

  import Select from "react-select";

  const isCorrectOptions = [{value:1,label:"Yes"},{value:0,label:"No"}]
  const questionTypes = [{value:'single-select',label:"Single Select"},{value:'mult-select',label:"Multi Select"}]
  

  


const AssessmentForm = ({
  page,
  getAssesment,
  assesmentData,
  updatePageData,
  updateTranslation,
  translations,
  selectedLanguage,
  assessmentDetails,
  updateAssessment,
  inductionImage,
  inductionImageLoading,
  inductionImageSuccess,
  inductionImageError,
  uploadInductionImage,
  setEditData
}) => {

  const [initialState, setInitialState] = useState(true);
  const [selectedOption, setSelectedOption] = useState(0);
  const [questionList, setQuestionList] = useState(assesmentData?.data?.questions);

  const updateQuestion = (questionIndex, updatedValue) => {
    var updatedQuestions = [...questionList];
    updatedQuestions[questionIndex] = {...updatedValue};
    setQuestionList(updatedQuestions);

    //send via api
    updateAssessment({ assessment_id: page?.data?.assessmentId,
        question_id: updatedValue?.id,
        question_type: updatedValue?.type,
        update_type: 2  //This means we are updating a question    
    });

  }

  const updateQuestionOption = (questionIndex, optionIndex, updatedValue) => {
    var updatedQuestions = [...questionList];

    var updatedOptions = [...questionList[questionIndex].options]
    updatedOptions[optionIndex] = updatedValue

    updatedQuestions[questionIndex] = {...questionList[questionIndex],options: updatedOptions};

    //console.log('updatedQuestions',updatedQuestions);

    setQuestionList(updatedQuestions);

    //send via api
    updateAssessment({ assessment_id: page?.data?.assessmentId,
        option_id: updatedValue?.id,
        is_correct: updatedValue?.is_correct,
        update_type: 1  //This means we are updating an option    
    });

  }


  useEffect(() => {
        getAssesment({ id: page?.data?.assessmentId });

        //updateAssessment({ assessment_id: page?.data?.assessmentId });
  }, []);


  useEffect(() => {
    setQuestionList(assesmentData?.data?.questions);
}, [assesmentData]);


  useEffect(() => {
    console.log('assessment data updated',assessmentDetails);
    
    //now re-fetch details
    if(assessmentDetails?.success == 1)
       getAssesment({ id: page?.data?.assessmentId });

}, [assessmentDetails]);


  useEffect(() => {
        
    if(initialState)
        return;
    
        if (inductionImage?.url) 
        {
           setEditData(true);
           
           updateAssessment({ assessment_id: page?.data?.assessmentId,
                              option_id: selectedOption,
                              image: inductionImage.id,
                              update_type: 1  //This means we are updating an option             
                            });

    
        }
   }, [inductionImage]);


  return <div>
        
        <br />
        <h4 className={classes.formHeading}>Questions</h4>

        {questionList?.map((questionItem,qIndex) =>
            <>
            <br />
                <h5 className={classes.formSubHeading}>Question {(qIndex + 1)}</h5>
                <Input
                    hasTooltip={true}
                    order="column"
                    value={translations[selectedLanguage.code]['dynamic.question.'+questionItem.id] ? translations[selectedLanguage.code]['dynamic.question.'+questionItem.id] : ''}
                    name={questionItem?.id ? questionItem?.id : "assessment_question_"+qIndex}
                    characterLimit={250}
                    onChange={(e) => {
                        updateTranslation(selectedLanguage.code,'dynamic.question.'+questionItem.id,e.target.value);                       
                    }}
                    label={"Question"}                        
                    placeholder={"Please type a question"}
                    error={""}
                    tooltipText={
                    "Question nr: " + (qIndex + 1)
                    }
                />


                <p>Type</p>
                    <Select
                        styles={{
                          control: () => ({
                            width: "100%",
                            height: 44,
                            display: "flex",
                            background: "#f8f8f8",
                            paddingLeft: "10px",
                            color: "#40507e",
                            border: "1px solid #E0E0E0",
                            boxSizing: "border-box",
                            borderRadius: "6px",
                            boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
                            fontFamily: "Open Sans",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }),
                        }}
                        placeholder={"Question Type"}
                        onChange={(val) => {
                          
                            updateQuestion(qIndex,{...questionItem,type: val?.value});
                          
                        }}
                        tabSelectsValue={selectedLanguage}
                        value={questionTypes.filter(
                          (option) => option.value === questionItem?.type
                        )}
                        options={questionTypes}
                        isSearchable={false}
                    />


                <br />
                <h6 className={classes.formSubHeading}>Question {(qIndex + 1)} Options</h6>
                {questionItem?.options.map((optionItem,oIndex) =>
                <>
                  <Input
                    hasTooltip={true}
                    order="column"
                    value={translations[selectedLanguage.code]['dynamic.questionoption.'+optionItem.id] ? translations[selectedLanguage.code]['dynamic.questionoption.'+optionItem.id] : ''}
                    name={optionItem?.id ? optionItem?.id : "assessment_question_option_"+oIndex}
                    characterLimit={250}
                    onChange={(e) => {
                        updateTranslation(selectedLanguage.code,'dynamic.questionoption.'+optionItem.id,e.target.value);                       
                    }}
                    label={"Title"}                        
                    placeholder={"Please type some text that represents this option"}
                    error={""}
                    tooltipText={
                    "Option nr: " + (oIndex + 1) + " Please leave empty to show image only."
                    }
                  />

                  <ImageUpload
                        isSuccess={inductionImageSuccess}
                        isUploading={inductionImageLoading}
                        isError={inductionImageError}
                        onDelete={() => {
                        //setFieldValue("image_url", null);
                        //setChangeCount((val) => val + 1);
                        }}
                        imageUrl={optionItem?.image}
                        description={"Upload a portrait sized photo from your device that represents the introduction image. Don't worry, we've got the web-friendly optimization covered."}
                        onLoad={(event) => {
                        if (event.target.files[0]) {
                            
                            setInitialState(false);
                            setSelectedOption(optionItem?.id);
                            uploadInductionImage({
                              image: event.target.files[0],
                            });
                            
                        }
                        }}
                    />

                  <p>Is this option correct?</p>
                    <Select
                        styles={{
                          control: () => ({
                            width: "100%",
                            height: 44,
                            display: "flex",
                            background: "#f8f8f8",
                            paddingLeft: "10px",
                            color: "#40507e",
                            border: "1px solid #E0E0E0",
                            boxSizing: "border-box",
                            borderRadius: "6px",
                            boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
                            fontFamily: "Open Sans",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }),
                        }}
                        placeholder={"Is the option correct?"}
                        onChange={(val) => {
                          
                            updateQuestionOption(qIndex,oIndex,{...optionItem,is_correct: val?.value});
                          
                        }}
                        tabSelectsValue={selectedLanguage}
                        value={isCorrectOptions.filter(
                          (option) => option.value === optionItem?.is_correct
                        )}
                        options={isCorrectOptions}
                        isSearchable={false}
                    />
                  <br />

                  <hr />
                 </>
                )}

                <button
                    id={"add_option"}
                    type="submit"
                    style={{width: '160px'}}
                    onClick={function(){
                      updateAssessment({ assessment_id: page?.data?.assessmentId,
                        question_id: questionItem?.id,
                        update_type: 4  //This means we are adding an option  
                      });
                        
                    }}
                    disabled={false}
                    className={classes.darkButton}
                    >
                    Add a new option
                </button>

            </>
            )}

            <br />
            <button
                    id={"add_question"}
                    type="submit"
                    style={{width: '260px'}}
                    onClick={function(){
                      
                      updateAssessment({ assessment_id: page?.data?.assessmentId,
                        question_type: questionTypes[0].value, //default option
                        update_type: 3  //This means we are adding a question
                      });
                      
                        
                    }}
                    disabled={false}
                    className={classes.darkButton}
                    >
                    Add a new question
                </button>


  </div>;
};

const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    assesmentData: makeSelectAssesment(),
    assessmentDetails: makeSelectAssessmentDetails(),
    inductionImage: makeSelectInductionImage(),
    inductionImageLoading: makeSelectInductionImageLoading(),
    inductionImageSuccess: makeSelectInductionImageSuccess(),
    inductionImageError: makeSelectInductionImageError(),
  });

  const mapDispatchToProps = (dispatch) => {
    return {
        getAssesment: (val) => dispatch(getAssesmentRequest(val)),
        updateAssessment: (val) => dispatch(updateAssessmentReq(val)),
        uploadInductionImage: (val) => dispatch(uploadInductionImageReq(val)),
    };
  };
  

  export default connect(mapStateToProps, mapDispatchToProps)(AssessmentForm);