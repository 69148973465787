import React,{ useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import ImageUpload from "../../../Hazards/components/imageUpload";
import Input from "../../../../../components/Supervisor/input";

import classes from "../editor.module.scss";

import {
    uploadInductionImageReq
  } from "store/supervisor/actions";
  
import {
    makeSelectInductionImage,
    makeSelectLoading,
    makeSelectInductionImageLoading,
    makeSelectInductionImageError,
    makeSelectInductionImageSuccess,
} from "store/supervisor/selector";

import SoundIcon from "../../../../Introductory/sound.svg";
import TimeIcon from "../../../../Introductory/time.svg";
import LocationIcon from "../../../../Introductory/location.svg";

const icons = {
  sound: SoundIcon,
  time: TimeIcon,
  location: LocationIcon,
};

const Introduction = ({
page,
inductionImage,
inductionImageLoading,
inductionImageSuccess,
inductionImageError,
uploadInductionImage,
updatePageData,
translations,
selectedLanguage,
updateTranslation,

setImageToCrop,
setIsCropModalOpen,
setCropperRatio,
croppedImage,
setImageBase64
}) => {

  const [imageUrl, setImageUrl] = useState(page?.data?.image);
  const [headingText, setHeadingText] = useState(page?.data?.content);
  const [recommendationText1, setRecommendationText1] = useState(page?.data?.recommendations[0]?.text);
  const [recommendationText2, setRecommendationText2] = useState(page?.data?.recommendations[1]?.text);
  const [recommendationText3, setRecommendationText3] = useState(page?.data?.recommendations[2]?.text);
  const [recommendations, setRecommendations] = useState(page?.data?.recommendations);
  const [initialState, setInitialState] = useState(true);
  const [isCropUploading, setIsCropUploading] = useState(false);

  const [selectedImageFile, setSelectedImageFile] = useState(null);

  var fr;

  const updateRecommendation = (item, value) => {
      var updatedRecommendations = [...recommendations];
      updatedRecommendations[item] = {...recommendations[item], text:value};
      setRecommendations(updatedRecommendations);
      updatePageData({key:'recommendations',value:recommendations},true);
  }

  
  useEffect(() => {
    if(initialState)
       return;

    if (inductionImage?.url) {
       setImageUrl(inductionImage?.url);

       //update both the data and preview that's why called twice
       updatePageData({page_id: page?.id, image_id: inductionImage?.id, image: inductionImage?.url });
       updatePageData({page_id: page?.id, image_id: inductionImage?.id, image: inductionImage?.url },true);

       //this is to avoid opening the cropper modal every time
       if(isCropUploading == false)
       {
          //open cropper
          setImageToCrop(inductionImage?.url);
          setCropperRatio( 4 / 3);
          
          fr = new FileReader();
          fr.readAsDataURL(selectedImageFile);

          setTimeout(function(){ setImageBase64(fr.result); }, 1000);

          setIsCropModalOpen(true);
       }

    }
  }, [inductionImage]);
  


  useEffect(() => {
    
    if (page?.data?.image) {
      setImageUrl(page?.data?.image);
    }

  }, []);

  const sendCroppedImage = async () => {
     
    let file = await fetch(croppedImage).then(r => r.blob()).then(blobFile => new File([blobFile], "cropped.png", { type: "image/png" }))
    
    console.log('cropped image - ',file);
    
    setIsCropUploading(true);

    uploadInductionImage({
      image: file,
      name: 'cropped.png'
    });

  }


  useEffect(() => {
    console.log('cropped image updated');
    
    if(croppedImage)
    {        
        sendCroppedImage();        
        setInitialState(false);        
    }
  }, [croppedImage]);

  useEffect(() => {
    console.log('induction image error',inductionImageError);
    console.log('induction image loading',inductionImageLoading);
    console.log('induction image success',inductionImageSuccess);

  }, [inductionImageError, inductionImageLoading, inductionImageSuccess]);



  return <div>

        <br />
        <h4 className={classes.formHeading}>Welcome Image</h4>

        <ImageUpload
            isSuccess={inductionImageSuccess}
            isUploading={inductionImageLoading}
            isError={inductionImageError}
            onDelete={() => {
            //setFieldValue("image_url", null);
            //setChangeCount((val) => val + 1);
            }}
            imageUrl={imageUrl}
            description={"Upload a portrait sized photo from your device that represents the introduction image. Don't worry, we've got the web-friendly optimization covered."}
            onLoad={(event) => {
            if (event.target.files[0]) {
                setInitialState(false);
                setIsCropUploading(false);

                setSelectedImageFile(event.target.files[0]);

                uploadInductionImage({
                  image: event.target.files[0],
                });
            }
            
            }}
        />
      
      <br />
      
      <h4 className={classes.formHeading}>Instruction Details</h4>

      <Input
          hasTooltip={true}
          order="column"
          value={page?.data['content_id'] ? translations[selectedLanguage.code][page?.data['content_id']] : ''}
          name={"content"}
          characterLimit={50}
          onChange={(e) => {
            //setHeadingText(e.target.value);
            updatePageData({key: 'content',value: e.target.value},true);
            updateTranslation(selectedLanguage.code,page?.data['content_id'],e.target.value);
          }}
          label={"Instruction Header"}
          placeholder={"Instruction Header"}
          error={""}
          tooltipText={
            "Instruction Header"
          }
      />

      <br />

      <div className={classes.formItemWrapper}>
         <div>
           <label className={classes.formLabel}>Icon</label>
            <img
              alt={`Icon`}
              className={classes.icon}
              src={icons[page?.data?.recommendations[0]?.icon]}
            />
         </div>
          

          <Input
              hasTooltip={true}
              order="column"
              value={recommendations[0]?.text_id ? translations[selectedLanguage.code][recommendations[0]?.text_id] : recommendations[0]?.text}
              name={"icon_1_text"}
              characterLimit={50}
              onChange={(e) => {
                updateRecommendation(0,e.target.value);
                updateTranslation(selectedLanguage.code, recommendations[0]?.text_id, e.target.value);
              }}
              label={"Icon 1 Text"}
              placeholder={"Icon 1 Text"}
              error={""}
              tooltipText={
                "Icon 1 Text"
              }
          />
      </div>

      <div className={classes.formItemWrapper}>
         <div>
           <label className={classes.formLabel}>Icon</label>
            <img
              alt={`Icon`}
              className={classes.icon}
              src={icons[page?.data?.recommendations[1]?.icon]}
            />
         </div>
          

          <Input
              hasTooltip={true}
              order="column"
              value={recommendations[1]?.text_id ? translations[selectedLanguage.code][recommendations[1]?.text_id] : recommendations[1]?.text}
              name={"icon_2_text"}
              characterLimit={50}
              onChange={(e) => {
                updateRecommendation(1,e.target.value);
                updateTranslation(selectedLanguage.code, recommendations[1]?.text_id, e.target.value);
              }}
              label={"Icon 2 Text"}
              placeholder={"Icon 2 Text"}
              error={""}
              tooltipText={
                "Icon 2 Text"
              }
          />
      </div>


    {page?.data?.recommendations[2]?.icon && (  
      <div className={classes.formItemWrapper}>
         <div>
           <label className={classes.formLabel}>Icon</label>
           
            <img
              alt={`Icon`}
              className={classes.icon}
              src={icons[page?.data?.recommendations[2]?.icon]}
            />
           
         </div>
          
          <Input
              hasTooltip={true}
              order="column"
              value={recommendations[2]?.text_id ? translations[selectedLanguage.code][recommendations[2]?.text_id] : recommendations[2]?.text}
              name={"icon_3_text"}
              characterLimit={50}
              onChange={(e) => {
                updateRecommendation(2,e.target.value);
                updateTranslation(selectedLanguage.code, recommendations[2]?.text_id, e.target.value);
              }}
              label={"Icon 3 Text"}
              placeholder={"Icon 3 Text"}
              error={""}
              tooltipText={
                "Icon 3 Text"
              }
          />
      </div>
    )}



  </div>;
};


const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    inductionImage: makeSelectInductionImage(),
    inductionImageLoading: makeSelectInductionImageLoading(),
    inductionImageSuccess: makeSelectInductionImageSuccess(),
    inductionImageError: makeSelectInductionImageError(),
  });
  const mapDispatchToProps = (dispatch) => {
    return {
      uploadInductionImage: (val) => dispatch(uploadInductionImageReq(val)),
    };
  };
  

  export default connect(mapStateToProps, mapDispatchToProps)(Introduction);

