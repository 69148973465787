///////////////////////////////////////////////////////////////////////////////
// function to create a page with given type through the induction editor
// inductionPageData: Pagedata that contains all induction pages
// pageType: Page type to be created
// id: induction id. Used for translation id generation
/////////////////////////////////////////////////////////////////////////////

export function createNewPage(inductionPageData, pageType, id)
{
  var pageData = [...inductionPageData];

  //find the highest id
  var nextId = 0;
  for(var i=0; i < pageData.length; i++)
  {
      if(pageData[i].id > nextId)
         nextId = pageData[i].id;
      
      if(pageData[i].type =='rules')
      {
         for(var j=0; j < pageData[i].data.rules.length; j++)
         {
            if(pageData[i].data.rules[j].id > nextId)
               nextId = pageData[i].data.rules[j].id;
         }
      }
  }
  nextId +=1;

   var newPageData = {
     id: nextId,
     type: pageType,
     url: '/' + pageType + '-' + nextId,
     title: '',
     title_id: 'induction.' + id + '.page.' + nextId + '.title',
     menu_name: "",
     menu_name_id: 'induction.' + id + '.page.' + nextId + '.menu.name',
     public: false,
     show_in_menu: true,
     data: {
       subtitle: '',
       subtitle_id: 'induction.' + id + '.page.' + nextId + '.subtitle',
     } 
   }

   if(pageType == 'introduction')
   {
      newPageData['data'] = {
        ...newPageData['data'],
        'content': '',
        'content_id': 'induction.' + id + '.page.' + nextId + '.content',
        'image': "https://keepsafe.work/images/02.01_welcome_A.jpeg",
        'recommendations': [
          {
            "icon": "sound",
            "text": "You will need sound for this induction",
            "text_id": 'induction.' + id + '.page.' + nextId + '.recommendation.1'
          },
          {
            "icon": "time",
            "text": "The time required is approx. 7 mins.",
            "text_id": 'induction.' + id + '.page.' + nextId + '.recommendation.2'
          }   
        ]

      }
   }
   else if(pageType == 'all-chapters-complete')
   {
        newPageData['data'] = {
         ...newPageData['data'],
        content: "You now understand our life saving rules. Let us walk you through a few last notes before we let you get on with it!",
        content_id: 'induction.' + id + '.page.' + nextId + '.content',
        subcontent: "Tap the next button below to proceed.",
        subcontent_id: 'induction.' + id + '.page.' + nextId + '.subcontent',

       }
   }
   else if(pageType == 'welcome')
   {
       newPageData['data'] = {
         ...newPageData['data'],
         video: [
                  {
                      "language": "eng",
                      "url": "https://vimeo.com/videoId",
                      "transcript": "Lorem ipsum dolor"
                  }
        ],
        video_subtitles: [
          "eng"
        ]

       }
   }
   else if(pageType == 'site-rules')
   {
      newPageData['data'] = {
        ...newPageData['data'],
        rules: [
                {
                    "content": "<p>Lorem ipsum dolor sit amet.</p>",
                    "content_id": 'induction.' + id + '.page.' + nextId + '.content.1',
                    "image": ""
                },
                {
                    "content": "<p>Aut mollitia repudiandae qui consequatur nisi qui fugit similique ut omnis nesciunt.</p>",
                    "content_id": 'induction.' + id + '.page.' + nextId + '.content.2',
                    "image": ""
                },
                {
                    "content": "<p>Cum iusto vero ut natus vitae in deserunt iure qui rerum consequatur ut temporibus nemo ea iure necessitatibus.</p>",
                    "content_id": 'induction.' + id + '.page.' + nextId + '.content.3',
                    "image": ""
                },
              ]
      }
   }
   else if(pageType == 'complete')
   {
         newPageData['data'] = {
          ...newPageData['data'],
         content: "And remember, if you are ever in doubt - ask your Supervisor.",
         content_id: 'induction.' + id + '.page.' + nextId + '.content',

        }
   }
   else if(pageType == 'post_login')
   {
        //fix type because it doesn't match the url
        newPageData['url'] = '/post-login-' + nextId;

        newPageData['data'] = {
          ...newPageData['data'],
          questions: [
            {
               id: 'induction.' + id + '.page.' + nextId + '.question.1',
               name: "Question 1"
            },
            {
              id: 'induction.' + id + '.page.' + nextId + '.question.2',
              name: "Question 2"
            }
          ]

        }
   }
   else if(pageType == 'rules')
   {
      newPageData['data'] = {
        ...newPageData['data'],
      rules:[]

      }        
   }
   else if(pageType == 'assessment')
   {
      //create a new assessment first
      //createAssessment({induction_id: id});

      newPageData['assessmentId'] = 0; // we will fill it on API response

      newPageData['data'] = {
        ...newPageData['data'],
        assessmentId: 0 // we will fill it on API response

      } 

   }

   pageData.push(newPageData);

   return pageData;

}


  //halilk: page Ids should be in ascending order in json data
  //because of how the progress is calculated. 
  //this function ensures that by re-assigning ids
  //Note: There is a side effect. When the Ids change, so does the progress of current users
  //They would have to re-take the induction
  export function regenerateInductionPageIds(pageData)
  {
     var updatedPageData = [...pageData];
     
     var currentId = 1;
     for(var i = 0; i < updatedPageData.length; i++)
     {
        //update the id and url
        updatedPageData[i] = {...updatedPageData[i], 
                              id: currentId, 
                              url: '/' + updatedPageData[i].type.replace('_','-') + '-' + currentId
                             }
        currentId +=1;

         if(updatedPageData[i].type == 'rules')
         {
            //each chapter should also have an id
            var rules = [...updatedPageData[i].data.rules];
            for(var j = 0; j < rules.length; j++)
            {
                rules[j] = {...rules[j], id: currentId}

                currentId +=1;

                updatedPageData[i] = {...updatedPageData[i], data: {...updatedPageData[i].data,rules:rules}}
            }
         }  
     }

     console.log('regeneratePageIds',updatedPageData);
     return updatedPageData;
  }


  export function deleteSelectedInductionChapter(item, inductionPageData, selectedPage, setSelectedPage)
  {
     //get the rules data from the actual page data
     var rules = [];

     var pageData = [...inductionPageData];

      for(var i=0; i < pageData.length; i++)
      {
          if(pageData[i].id == selectedPage.id)
          {
              rules = [...pageData[i].data.rules];
          }
      }

     //now delete the selected one
     console.log('deleteRules - rules',rules);
     console.log('deleteRules - id',item);
     for(var i=0; i < rules.length; i++)
      {
          if(rules[i].id == item.id)
          {
            rules.splice(i, 1); //delete one item only
          }
      }

      //update selected page
      setSelectedPage({...selectedPage,data:{...selectedPage.data, rules: rules}});

      //update global page data
      for(var i=0; i < pageData.length; i++)
      {
          if(pageData[i].id == selectedPage.id)
          {
            pageData[i] = {...selectedPage,data:{...selectedPage.data, rules: rules}}
          }
      }
  
      //re-generate ids
      pageData = regenerateInductionPageIds(pageData);

      return pageData;

          
  }

  //This is our gigantic induction save prep function so we will go step by step
  export function prepareDataToSaveInduction(induction, inductionPageData, selectedPage, selectedRule, selectedSection)
  {
    //update page data
    var data = { ...induction.data }
    var pageData = [...inductionPageData]

    for(var i = 0; i < inductionPageData.length; i++)
    {
        if(inductionPageData[i].id == selectedPage.id)
        {
            //console.log('found id: '+ selectedPage.id);
            pageData[i] = {...selectedPage};
        }


        if(inductionPageData[i].data.rules && inductionPageData[i].type == 'rules')
        {
          var ruleData = [...inductionPageData[i].data.rules]
          for(var j = 0; j < inductionPageData[i].data.rules.length; j++)
          {
              if(inductionPageData[i].data.rules[j].id == selectedRule.id && selectedRule.id > 0)
              {
                  //console.log('found id: '+ selectedRule.id);
                  ruleData[j] = {...selectedRule};
              }
              
              if(inductionPageData[i].data.rules[j]?.sections)
              {
                
                var updatedSections = [...inductionPageData[i].data.rules[j].sections];
                for(var k = 0; k < updatedSections.length; k++)
                {
                    if(inductionPageData[i].data.rules[j].sections[k]?.header_id == selectedSection?.header_id
                      && inductionPageData[i].data.rules[j].sections[k]?.title_id == selectedSection?.title_id
                    )
                    {
                      updatedSections[k] = selectedSection;
                    }

                    ruleData[j] = {...ruleData[j],sections:updatedSections};

                }
              }

          }
          
          //console.log('ruleData', ruleData);
          //doesn't work for now
          var updatedData = {...pageData[i],data:{...pageData[i].data, rules: ruleData}}
          pageData[i] = {...updatedData};
          //pageData[i] = {...pageData[i], 'rules': ruleData};

        }
    }

    data.pages = pageData;


    console.log('data.pages',data.pages);

    return data;
  }


    //adding a new chapter to the rules page
    export function createNewChapter(item, inductionPageData, selectedPage, id)
    {
      var rules = [...item.data?.rules];
  
      const newId = rules.length + 1;
  
      //template
      rules.push({
          "title": "Chapter " + newId,
          "title_id": "induction." + id + ".page." + item?.id + ".rules." + newId + ".title",
          "id": (inductionPageData.length + 1),
          "show_in_menu": true,
          "icon": "https://keepsafe.work/images/icons/drugs_and_alcohol.svg",
          "iconType": "ImageAndText",
          "iconColor": "#28353C",
          "iconBackground": "linear-gradient(95.67deg, #FFC200 0.49%, #EEB954 47.62%, #E0D80E 100.29%)",
          "textColor": "#28353C",
          "background": "linear-gradient(95.67deg, #FFC200 0.49%, #EEB954 47.62%, #E0D80E 100.29%)",
          "ctaColor": "#FFF",
          "ctaBorder": {
              "type": "solid",
              "color": "#FFF"
          },
          "progressColor": "#28353C",
          "progressEmptyColor": "#FFF",
          "border": "linear-gradient(to right, #FFC200, #E0D80E)",
          "sections": []
      });
  
      console.log('rules',rules);
  
      //update induction data
      var pageData = [...inductionPageData];
  
      for(var i=0; i < pageData.length; i++)
      {
          if(pageData[i].id  == selectedPage.id)
          {
              pageData[i] = {...pageData[i],data: {...pageData[i].data, rules: rules}}
          }
      }
  
      pageData = regenerateInductionPageIds(pageData);
      return pageData;
  
    }


  export function createNewChapterSection(selectedRule, id, inductionPageData, selectedPage)
  {
    var sections = [...selectedRule.sections];

    const newId = sections.length + 1;
    sections.push({
        "title": "New Section",
        "title_id": "induction." + id+ ".page."+selectedPage.id+".rule."+selectedRule.id+".section."+newId+".title",
        "data": {
          "components": []
        }
    });

    console.log('sections',sections);

    //update induction data
    var pageData = [...inductionPageData];

    for(var i=0; i < pageData.length; i++)
    {
        if(pageData[i].id  == selectedPage.id)
        {
            
            var rules = [...pageData[i].data.rules];
            for(var j=0; j < rules.length; j++)
            {
                if(rules[j].id == selectedRule.id)
                {
                  rules[j] = {...rules[j],sections:sections};
                }
            }
            pageData[i] = {...pageData[i],data: {...pageData[i].data, rules: rules}}
        }
    }

    return pageData;
    
  }

  export function deleteSelectedInductionPage(inductionPageData, selectedPage)
  {
    var pageData = [...inductionPageData];

    for(var i=0; i < pageData.length; i++)
    {
        if(pageData[i].id == selectedPage.id)
        {
          pageData.splice(i, 1); //delete one item only
        }
    }

    pageData = regenerateInductionPageIds(pageData);
    return pageData;

  }