import React, { useState } from "react";
import classes from "./modal.module.scss";
import ReactLoading from "react-loading";
import Input from "../input";
import Cropper from 'react-easy-crop'
import getCroppedImg from "pages/Supervisor/Inductions/Editor/cropImage";

const CropModal = ({
  open,
  cancel,
  action,
  loading,
  image,
  setCroppedImage,
  imageBase64,
  cropperRatio
}) => {
  const [option, setOption] = useState(false);
  const [name, setName] = useState("");

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(false)

  const showCroppedImage = async (croppedAreaPixels) => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        imageBase64,
        croppedAreaPixels,
        rotation
      )
      console.log('donee', { croppedImage })
      setCroppedImage(croppedImage)
    } catch (e) {
      console.log('cropper error');
      console.error(e)
    }
  }


  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log('cropper',croppedArea, croppedAreaPixels)
    
    setCroppedAreaPixels(croppedAreaPixels);
  }

  return (
    <>
      {open && (
        <>
          <div className={classes.modalContainer}>
            <div
              className={classes.modal}
              style={{
                width: "608px",
                height: "482px",
                alignItems: "center",
              }}
            >
              <>
                <div className={classes.headerContainer}>
                  <span>Crop Image</span>
                </div>
                <div
                  style={{ width: "80%", margin: "0 auto" }}
                  className={classes.bodyContainer}
                >
                  <div style={{position:'relative',height: '370px',width:'100%'}}>
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={cropperRatio}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                  </div>
                  
                </div>
                <div className={classes.footerContainer}>
                  <div
                    className={classes.cancelButton}
                    onClick={() => cancel()}
                  >
                    <span>Don't Crop</span>
                  </div>
                  <div
                    onClick={() => {
                        
                        showCroppedImage(croppedAreaPixels);

                        action({
                          name: name,
                          callBack: () => {
                            cancel();
                          },
                        });
                      
                    }}
                    className={classes.sendButton}
                  >
                    {!loading ? (
                      <span>Crop</span>
                    ) : (
                      <ReactLoading
                        width={"20px"}
                        height={"20px"}
                        color={"#ffffff"}
                        type={"spin"}
                      />
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
          <div className={classes.modalBackground} onClick={() => cancel()} />
        </>
      )}
    </>
  );
};

export default CropModal;
