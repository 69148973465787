import React, { useEffect, useState, useCallback } from "react";
import RouteLeavingGuard from "../../../../components/UI/routeGuard";

import Menu from "components/Supervisor/menu";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getInductionDetailReq, updateInductionReq, createAssessmentReq } from "store/supervisor/actions";
import {
  makeSelectLoading,
  makeSelectUsers,
  makeSelectInductionDetail,
  makeSelectAssessmentDetails
} from "store/supervisor/selector";

import {
  makeSelectLanguages,
} from "store/language/selector";

import { isNil } from "lodash";
import ReactLoading from "react-loading";
import classes from "./editor.module.scss";
import queryString from "query-string";

import { getComponent } from "../../../../components/UI/section/componentHandler";
import Input from "../../../../components/Supervisor/input";
import Select from "react-select";

import pageIcon from "./icons/page_icon.png";
import pageIconSelected from "./icons/induction_check.svg";
import pageItemHeaderIcon from "./icons/pageitem_header.png";
import IconComponent from "../../../../components/UI/IconComponent";
import { Header } from "components";

//Forms for different page types
import Prequalification from "./Forms/prequalification";
import Introduction from "./Forms/introduction";
import Video from "./Forms/video";
import AllChaptersCompleteForm from "./Forms/allChaptersComplete";
import InductionCompleteForm from "./Forms/inductionComplete";
import RulesForm from "./Forms/rules";
import ChaptersForm from "./Forms/chapters";
import AssessmentForm from "./Forms/assessment"
import ChapterSettingsForm from "./Forms/chapterSettings"
import VREditorForm from "./Forms/vrEditor"


//pages for preview
import Introductory from "../../../Introductory";
import Welcome from "../../../Welcome";
import LookAround from "../../../LookAround";
import Induction from "../../../Induction";
import AllChaptersComplete from "../../../AllChaptersComplete";
import SiteRules from "../../../SiteRules";
import Complete from "../../../Complete";
import AssesmentPage from "../../../Assessment";
import PostLogin from "../../../PostLogin";

//components
import PageItem from "./Components/PageItem";

import { select } from "redux-saga/effects";

import Modal from "components/Supervisor/modal";
import CropModal from "components/Supervisor/modal/cropper";

import { previousFriday } from "date-fns";

import { useDrop } from 'react-dnd';
import update from 'immutability-helper'

//helper functions
import {createNewPage, 
        regenerateInductionPageIds, 
        deleteSelectedInductionChapter,
        prepareDataToSaveInduction,
        createNewChapter,
        createNewChapterSection,
        deleteSelectedInductionPage
       } from './Helpers/inductionFunctions';

import {combineTranslations, 
       refreshInductionTranslationData,
       prepareTranslatedHotspots,
       prepareTranslatedFlipcards,
       prepareTranslatedCarousel,
       prepareTranslatedCardsort
       } from './Helpers/translationFunctions';
import EditorMain from "./Forms/editorMain";


const InductionEditor = ({
  history,
  loading,
  induction,
  getInductions,
  updateInduction,
  languages,
  createAssessment,
  assessmentDetails
}) => {
  const id = queryString.parse(window.location.search).id;

  const [editData, setEditData] = useState(false); //used for route guard when changing page
  const [isThereUnsavedEdits, setIsThereUnsavedEdits] = useState(false);
  const [itemToMoveTo, setItemToMoveTo] = useState({}); //This is used when user confirms the modal
 
  //Below are to update the induction content
  const [selectedPage, setSelectedPage] = useState({title: ''});
  const [selectedRule, setSelectedRule] = useState({title: ''});
  const [selectedSection, setSelectedSection] = useState({title: ''});
  
  //Below are only to update the preview
  const [selectedPagePreview, setSelectedPagePreview] = useState({title: ''});


  const [languageOptions, setLanguageOptions] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({code: 'en', name: "English"});
  const [translations, setTranslations] = useState({});
  const [previewCounter, setPreviewCounter] = useState(1);

  //creating new pages
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(0);
  const [pageType, setPageType] = useState();
  const [inductionPageData, setInductionPageData] = useState([]);

  //image crop
  const [isCropModalOpen, setIsCropModalOpen] = useState(0);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null)
  const [imageBase64, setImageBase64] = useState(null)
  const [cropperRatio, setCropperRatio] = useState( 9 / 16)
  
  //D&D For the pages
  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = inductionPageData[dragIndex]
      setInductionPageData(
        update(inductionPageData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )

      //regenerate the ids since it affects the progress
      setTimeout(() => {
        regenerateInductionPageIds(update(inductionPageData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }));
      }, 1000);
    },
    [inductionPageData],
  )


  useEffect(() => {
    if (languages?.length) {
      const dfList = languages.map((item) => {
        return {
          value: item.code,
          label: item.name,
        };
      });

      setLanguageOptions(dfList);

      let newTransalation = combineTranslations(languages,induction?.translations);
      setTranslations(newTransalation);

      console.log('initial translations',newTransalation);

    }
  }, [languages]);


  useEffect(() => {
    getInductions({id:id});
  }, []);

  useEffect(() => {
    console.log('induction:',induction);

    //set the first page as selected
    if(induction?.data?.pages.length > 0)
       updateSelectedPage(induction.data.pages[0]);
    
    if(induction?.data?.pages)
       setInductionPageData(induction.data.pages);

    //refresh translations
    let newTransalation = combineTranslations(languages,induction?.translations);
    setTranslations(newTransalation);
    /*
    setTimeout(function(){
      setPreviewCounter(0);

      setSelectedLanguage({
        code: 'en',
        name: 'English',
      });
    },1000);
    */

  }, [induction]);

  
  //This is called when we get a response from assessment API 
  //which means the new assessment is created. We put the id to the related page
  //type = 10 means new assessment
  useEffect(() => {
    console.log('assessmentDetails',assessmentDetails);

    if(parseInt(assessmentDetails?.success) == 1 & parseInt(assessmentDetails?.type) == 10)
    {
        //add the assessment id to the page
        var pageData = [...inductionPageData];

        for(var i=0; i < pageData.length; i++)
        {
            if(pageData[i].type == 'assessment')
            {
                if(pageData[i]['assesmentId'] == 0 || pageData[i]['assessmentId'] == 0)
                {
                   delete pageData[i]['assesmentId']; //this is the old way with a typo
                   pageData[i]['assessmentId'] = assessmentDetails?.id;
                   pageData[i]['data'] = {...pageData[i]['data'], assessmentId: assessmentDetails?.id};
                }
                   
            }
        }

        setInductionPageData(pageData);

    }

  }, [assessmentDetails]);


  //used for updating page data to reflect the current selected language translations
  function refreshTranslationData(pageData = null)
  {
    var updatedPageData = refreshInductionTranslationData(pageData, selectedPagePreview, translations, selectedLanguage);
    setSelectedPagePreview(updatedPageData);

    if(updatedPageData?.type == 'rules' && selectedSection && translations[selectedLanguage.code][selectedSection?.title_id])
    {
       setSelectedSection({...selectedSection, title: translations[selectedLanguage.code][selectedSection?.title_id]})
    }  

  }

  useEffect(() => {
    console.log('language changed',selectedLanguage);

    if(previewCounter > 0)
      return;

    refreshTranslationData();

    setPreviewCounter(2);

  }, [selectedLanguage]);



  function updateSelectedPage(item)
  {
    if(editData)
    {
      setIsThereUnsavedEdits(true);
      setItemToMoveTo(item);
    }
    else
    {
      setIsThereUnsavedEdits(false);
     
      setSelectedPage(item);
      setSelectedPagePreview(item); //to preview
      setSelectedRule({title: ''}); //reset
      setSelectedSection({title: ''}); //reset
      
      //refresh translations
      setPreviewCounter(0);

      setSelectedLanguage({
          code: 'en',
          name: 'English',
      });

    }
  }

  function updateSelectedRule(item)
  {
     setSelectedRule(item);
     setSelectedSection({title: ''}); //reset
     //console.log(item);
  }

  function updateSelectedSection(item)
  {
     setSelectedSection(item);

     //refresh translations
     setPreviewCounter(0);
     setSelectedLanguage({
         code: 'en',
         name: 'English',
     });

     //console.log(item);
  }  

  function updateTranslation(language,key,content)
  {
      var newTranslation = {...translations}
      newTranslation[language][key] = content;
      setTranslations(newTranslation);

      setEditData(true); //trigger change checker

      console.log('translations',newTranslation);
  }

  //Induction save function
  function handleSubmit()
  {
    var data = prepareDataToSaveInduction(induction, inductionPageData, selectedPage, selectedRule , selectedSection);

    //DO NOT OPEN UNTIL YOU ARE SURE DATA IS ASSEMBLED CORRECTLY!
    //translations only for now
    updateInduction({'id': id, 'data': JSON.stringify(data), 'translations': JSON.stringify(translations)})

    //reset the status
    setEditData(false);
    setIsThereUnsavedEdits(false);

  }
  
  //function that is called by the special forms
  function updatePageData(val,isPreview=false)
  {
    setEditData(true); //trigger change checker

    console.log('update page data called',val);

    var currentData = {};

    if(isPreview)
       currentData = {...selectedPagePreview}
    else
       currentData = {...selectedPage}

    if(val?.key)
    {
      var updatedPage = {...currentData, data:{...currentData.data, 
        [val?.key]: val?.value
      }}
    }
    else
    {
      var updatedPage = {...currentData, data:{...currentData.data, 
                                                image_id: val?.image_id,
                                                image: val?.image
                                              }}
    }
    console.log('updatedPage',updatedPage);
    
    if(isPreview)
      setSelectedPagePreview(updatedPage);
    else
      setSelectedPage(updatedPage);
  }
  
 
  //Change Modal actions
  function closeModal()
  {
     setIsThereUnsavedEdits(false);
  }

  function modalAction()
  {
     //move to the next page without saving
     setIsThereUnsavedEdits(false);
     setEditData(false);
     updateSelectedPage(itemToMoveTo);
     
  }

  //create modal actions
  function closeCreateModal()
  {
     setIsCreateModalOpen(false);
  }


  function createPage()
  {

    var pageData = createNewPage(inductionPageData,pageType,id);

    setInductionPageData(pageData);

    setIsCreateModalOpen(false);

    //re-generate ids
    regeneratePageIds(pageData);

    //if we are adding an assessment page, then create the assessment
    if(pageType == 'assessment')
    {
         //create a new assessment
         createAssessment({induction_id: id});
    }

  }

  //halilk: page Ids should be in ascending order in json data
  //because of how the progress is calculated. 
  //this function ensures that by re-assigning ids
  //Note: There is a side effect. When the Ids change, so does the progress of current users
  //They would have to re-take the induction
  function regeneratePageIds(pageData)
  {
    var updatedPageData = regenerateInductionPageIds(pageData);
    setInductionPageData(updatedPageData);
  }

  
  //when the create new rule button is pressed
  function createNewRule(item)
  {
    var pageData = createNewChapter(item, inductionPageData, selectedPage, id);
    setInductionPageData(pageData);
  }

  function createNewSection()
  {
    var pageData = createNewChapterSection(selectedRule, id, inductionPageData, selectedPage);
    setInductionPageData(pageData); 
  }

  function deleteSelectedPage()
  {
    var pageData = deleteSelectedInductionPage(inductionPageData, selectedPage);
    setInductionPageData(pageData);

    //make the first page selected
    if(pageData.length > 0)
      setSelectedPage(pageData[0]);

  }

  function deleteSelectedChapter(item)
  {
    //TODO: remove the setSelectedPage dependence here
    var pageData = deleteSelectedInductionChapter(item, inductionPageData, selectedPage, setSelectedPage);
    setInductionPageData(pageData);
          
  }

//crop modal actions
function closeCropModal()
{
   setIsCropModalOpen(false);
}

function cropImage()
{
   console.log('crop image called');
   setIsCropModalOpen(false);
}



  return (
    <>
      {loading ? (
        <div className={classes.loadingContainer}>
          <ReactLoading
            type={"spin"}
            color={"#20a8d8"}
            height={300}
            width={300}
          />
        </div>
      ) : (
        <div className={classes.InductionEditorContainer}>

          <div className={classes.inductionMenuWrapper}>  
            <h3>Induction</h3>
            
            <div id="div_pageItemWrapper" className={classes.pageItemWrapper}>
                {inductionPageData.map((item,i) => (
                <div>
                  <PageItem 
                    item={item}
                    isSelected={selectedPage.id == item.id}
                    onClick={() => updateSelectedPage(item)}
                    translations={translations}
                    selectedLanguage={selectedLanguage}
                    id={item?.id}
                    moveItem={moveItem}
                    index={i}
                  />            
                  

                  {(item.data?.rules && item.id == selectedPage.id && item.type=='rules') && (
                    <div className={classes.ruleItemWrapper}>                     
                      {item.data?.rules?.map((item) => (
                         <div>
                          <div onClick={() => updateSelectedRule(item)} className={classes.ruleItem} style={{background:item?.background, color: item?.textColor,borderColor: item?.title == selectedRule.title ? '#44C032' : '#fff'}}>
                            <div className={classes.pageItemHeader}> <img src={pageItemHeaderIcon}/> </div>
                            
                            <div className={classes.ruleIconWrapper}>
                              <div style={{backgroundColor: !isNil(item?.iconBackground) ? item?.iconBackground : item?.color}}>
                                {!isNil(item?.iconType) ? (
                                  <IconComponent
                                    icon={item?.iconType}
                                    color={item?.iconColor ? item?.iconColor : "white"}
                                    size={"80%"}
                                  />
                                ) : (
                                  <img src={item?.icon} />
                                )}
                              </div>
                            </div>
                            
                            {item.title_id ? translations[selectedLanguage.code][item.title_id] : item.title}
                          </div>

                          {item?.title == selectedRule.title && item.sections?.map((sectionItem) => (
                            <div onClick={() => updateSelectedSection(sectionItem)} className={classes.sectionItem} style={{background:item?.background, color: item?.textColor,borderColor: sectionItem?.title == selectedSection.title ? '#44C032' : '#fff'}}>
                               {sectionItem.title_id ? translations[selectedLanguage.code][sectionItem.title_id] : sectionItem.title}
                            </div>
                          ))}

                          {item?.title_id == selectedRule.title_id && (
                            <button
                            id={"save"}
                            type="submit"
                            style={{width: '60px', marginLeft: '40px'}}
                            onClick={createNewSection}
                            disabled={!isNil(loading) ? false : loading}
                            className={classes.darkButton}
                            >
                            +
                            </button>
                          )}

                          </div>
                      
                      ))}   

                      {/* Add a new chapter */}
                      <br />
                      <button
                            id={"new_rule"}
                            type="submit"
                            style={{width: '100px'}}
                            onClick={function(){createNewRule(item)}}
                            disabled={!isNil(loading) ? false : loading}
                            className={classes.darkButton}
                            >
                            New Chapter
                          </button>

                    </div>
                  )}
                </div>
                ))}
            </div>

            
            
            {/*selectedRule.sections && (
              <div className={classes.sectionItemWrapper}>
                {selectedRule.sections?.map((item) => (
                
                    <div onClick={() => updateSelectedSection(item)} className={classes.sectionItem}>{item.title}</div>
                
                ))}      
              </div>
                )*/}
          </div>
            
          <div className={classes.pageEditorWrapper}>                 
            <h3>Editor</h3>
            <div className={classes.pageEditorContainer}>  
                
                {/* General form */} 
                <EditorMain 
                   languageOptions={languageOptions}
                    selectedPage={selectedPage}
                    updatePageData={updatePageData}
                    setPreviewCounter={setPreviewCounter}
                    setSelectedLanguage={setSelectedLanguage}
                    selectedLanguage={selectedLanguage}
                    updateTranslation={updateTranslation}
                    setSelectedPagePreview={setSelectedPagePreview}
                    selectedRule={selectedRule}
                    selectedSection={selectedSection}
                    setSelectedSection={setSelectedSection}
                    translations={translations}
                    selectedPagePreview={selectedPagePreview}
                />

                {/* Here we bring the forms based on selected page type */} 

                {selectedPage.type == 'post_login' && (
                  <Prequalification page={selectedPagePreview} 
                                    updatePageData={updatePageData} 
                                    translations={translations} 
                                    selectedLanguage={selectedLanguage} 
                                    updateTranslation={updateTranslation}
                  />
                )}

                {selectedPage.type == 'introduction' && (
                  <Introduction page={selectedPagePreview} 
                                updatePageData={updatePageData} 
                                translations={translations} 
                                selectedLanguage={selectedLanguage} 
                                updateTranslation={updateTranslation}
                                
                                //Image cropper
                                setImageToCrop={setImageToCrop}
                                setIsCropModalOpen={setIsCropModalOpen}
                                croppedImage={croppedImage}
                                setImageBase64={setImageBase64}
                                setCropperRatio={setCropperRatio}
                  />
                )}

                {selectedPage.type == 'welcome' && (
                  <Video page={selectedPage} updatePageData={updatePageData} />
                )}

                {selectedPage.type == 'all-chapters-complete' && (
                  <AllChaptersCompleteForm page={selectedPagePreview} 
                                           updatePageData={updatePageData} 
                                           translations={translations} 
                                           selectedLanguage={selectedLanguage} 
                                           updateTranslation={updateTranslation}                  
                  />
                )}

                {selectedPage.type == 'complete' && (
                  <InductionCompleteForm page={selectedPagePreview} 
                                         updatePageData={updatePageData}
                                         translations={translations} 
                                         selectedLanguage={selectedLanguage} 
                                         updateTranslation={updateTranslation}
                  />
                )}

                {selectedPage.type == 'assessment' && (
                  <AssessmentForm page={selectedPagePreview} 
                                  updatePageData={updatePageData}
                                  translations={translations} 
                                  selectedLanguage={selectedLanguage} 
                                  updateTranslation={updateTranslation}
                                  setEditData={setEditData}
                  />
                )}

                {selectedPage.type == 'site-rules' && (
                  <RulesForm page={selectedPagePreview} 
                             updatePageData={updatePageData} 
                             translations={translations} 
                             selectedLanguage={selectedLanguage} 
                             updateTranslation={updateTranslation}
                             induction_id={id}
                  
                  />
                )}

                {selectedPage.type == 'rules' && selectedSection.title && (
                  <ChaptersForm page={selectedPagePreview} 
                                chapter={selectedRule} 
                                section={selectedSection} 
                                updatePageData={updatePageData} 
                                setSelectedSection={setSelectedSection} 
                                translations={translations} 
                                selectedLanguage={selectedLanguage} 
                                updateTranslation={updateTranslation}
                                setEditData={setEditData}
                                induction_id={id}

                                //Image cropper
                                setImageToCrop={setImageToCrop}
                                setIsCropModalOpen={setIsCropModalOpen}
                                croppedImage={croppedImage}
                                setImageBase64={setImageBase64}
                                setCropperRatio={setCropperRatio}
                  />
                )}

                {selectedPage.type == 'rules' && selectedRule.title && !selectedSection.title && (
                  <ChapterSettingsForm page={selectedPagePreview} 
                                chapter={selectedRule} 
                                updatePageData={updatePageData} 
                                translations={translations} 
                                selectedLanguage={selectedLanguage} 
                                updateTranslation={updateTranslation}
                                setEditData={setEditData}
                                induction_id={id}
                                updateSelectedRule={updateSelectedRule}
                  />
                )}

                {selectedPage.type == 'take-a-look' && (
                  <VREditorForm page={selectedPagePreview} 
                                chapter={selectedRule} 
                                updatePageData={updatePageData} 
                                translations={translations} 
                                selectedLanguage={selectedLanguage} 
                                updateTranslation={updateTranslation}
                                setEditData={setEditData}
                                induction_id={id}
                                updateSelectedRule={updateSelectedRule}
                  />
                )}




                <br />
                <button
                    id={"save"}
                    type="submit"
                    style={{width: '250px'}}
                    onClick={handleSubmit}
                    disabled={!isNil(loading) ? false : loading}
                    className={classes.darkButton}
                  >
                    Save
                </button>

                <br />
                {selectedPage.id && !selectedRule.id && (
                <button
                    id={"delete"}
                    type="submit"
                    style={{width: '250px'}}
                    onClick={deleteSelectedPage}
                    disabled={!isNil(loading) ? false : loading}
                    className={classes.deleteButton}
                  >
                    Delete this page
                </button>
                )}

                {selectedRule.id && (
                  <button
                    id={"delete"}
                    type="submit"
                    style={{width: '250px'}}
                    onClick={function(){deleteSelectedChapter(selectedRule)}}
                    disabled={!isNil(loading) ? false : loading}
                    className={classes.deleteButton}
                  >
                    Delete this chapter
                </button>
                )}

                <br />





            </div>
          </div>

          <div className={classes.pagePreviewWrapper}>
            <h3>Preview</h3>
            <div className={classes.pagePreviewContainer}>
              <div className={classes.pagePreviewContent}>
                  <div className={classes.pagePreviewHeaderWrapper}>
                    <Header
                    menu={false}
                    open={false}
                    setOpen={function(){}}
                  />
                  </div>

                  {/* These are different page components for preview - opened in preview mode */}

                  {selectedPage?.type == "introduction" && previewCounter > 0 && (
                    <div>
                        <Introductory selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}

                  {selectedPage?.type == "welcome" && (
                    <div>
                        <Welcome selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}

                  {selectedPage?.type == "take-a-look" && (
                    <div>
                        <LookAround selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}

                  {selectedPage?.type == "all-chapters-complete" && (
                    <div>
                        <AllChaptersComplete selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}

                  {selectedPage?.type == "site-rules" && previewCounter > 0 && (
                    <div>
                        <SiteRules selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}

                  {selectedPage?.type == "complete" && previewCounter > 0 &&  (
                    <div>
                        <Complete selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}


                  {selectedPage?.type == "assessment" && (
                    <div>
                        <AssesmentPage selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}


                  {selectedPage?.type == "post_login" && (
                    <div>
                        <PostLogin selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}




                  {selectedPage?.type == "rules" && selectedSection?.title == '' && (
                      
                    <div>
                        <Induction selectedInduction={induction} 
                                   induction={induction} isPreview={true} 
                                   pageData={selectedPagePreview} 
                                   history={history} 
                        />
                    </div>
                      
                  )}

                  {selectedSection && previewCounter > 0 && (
                    <div>
                        {selectedSection.data?.components?.map((item) =>
                            
                          item.type != 'card_sort__' ? getComponent(item.type, {
                            ...item,
                            title: translations[selectedLanguage.code][item?.title_id] ? translations[selectedLanguage.code][item?.title_id] : '-',
                            header: translations[selectedLanguage.code][item?.header_id] ? translations[selectedLanguage.code][item?.header_id] : '-',
                            button_label: translations[selectedLanguage.code][item?.button_label_id],
                            conclusion_title: translations[selectedLanguage.code][item?.conclusion_title_id],
                            conclusion_content: translations[selectedLanguage.code][item?.conclusion_content_id],
                            success_modal_title: translations[selectedLanguage.code][item?.success_modal_title_id],
                            success_modal_description: translations[selectedLanguage.code][item?.success_modal_description_id],
                            category_title: translations[selectedLanguage.code][item?.category_title_id] ? translations[selectedLanguage.code][item?.category_title_id] : '-',
                            content: item.type != 'carousel' ? translations[selectedLanguage.code][item?.content_id] : prepareTranslatedCarousel(item.content, translations, selectedLanguage),
                            hotspots: item.type == 'hotspots' ? prepareTranslatedHotspots(item.hotspots, translations, selectedLanguage) : '',
                            cards: item.type == 'flipcard' ? prepareTranslatedFlipcards(item.cards, translations, selectedLanguage) : item.type == 'card_sort' ? prepareTranslatedCardsort(item.cards, translations, selectedLanguage) : item?.cards
                          }, () => {},true) : ''
                          
                        )}
                    </div>
                  )}

              </div>
            </div>
          </div>

          
          <div className={classes.buttonContainer}>
            <div className={classes.buttonWrapper}>
              <div style={{ justifyContent: "flex-end" }} className={classes.flex}>

                <button
                  disabled={false}
                  className={classes.darkButton}
                  onClick={() => {
                    if(induction?.status == 1)
                      updateInduction({'id': id, 'status': 0})
                    else
                      updateInduction({'id': id, 'status': 1})
                  }}
                >
                  {induction?.status == 1 ? "Move to Draft" : "Publish"}
                </button>
                
              </div>
              &nbsp; 
              <div style={{ justifyContent: "flex-end" }} className={classes.flex}>
                <button
                  disabled={false}
                  className={classes.darkButton}
                  onClick={() => {
                     setIsCreateModalOpen(true);
                  }}
                >
                  {"Create a page"}
                </button>
              </div>

            </div>
          </div>          


          

            
        </div>
        

      )}

      <RouteLeavingGuard
        // When should shouldBlockNavigation be invoked,
        // simply passing a boolean
        // (same as "when" prop of Prompt of React-Router)
        confirmModal={true}
        when={editData == true}
        // Navigate function
        navigate={(path) => history.push(path)}
        // Use as "message" prop of Prompt of React-Router
        shouldBlockNavigation={(location) => {
          return true;
        }}
      />

      <Modal
        open={isThereUnsavedEdits}
        cancel={closeModal}
        action={modalAction}
        deleteTitle={"You have unsaved changes"}
        deleteDescription={
          "If you continue, your changes will be lost. Do you want to continue?"
        }
        buttonTitle={"Continue"}
      />

      <Modal
        open={isCreateModalOpen}
        cancel={closeCreateModal}
        action={createPage}
        type={'editor'}
        buttonTitle={"Create"}
        updateData={function(key,value){
           if(key == 'type')
              setPageType(value);
        }}
      />

      <CropModal
        open={isCropModalOpen}
        cancel={closeCropModal}
        action={cropImage}
        buttonTitle={"Crop"}
        image={imageToCrop}
        imageBase64={imageBase64}
        setCroppedImage={setCroppedImage}
        cropperRatio={cropperRatio}
      />



    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  induction: makeSelectInductionDetail(),
  languages: makeSelectLanguages(),
  assessmentDetails: makeSelectAssessmentDetails(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getInductions: (val) => dispatch(getInductionDetailReq(val)),
    updateInduction: (val) => dispatch(updateInductionReq(val)),
    createAssessment: (val) => dispatch(createAssessmentReq(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InductionEditor);
