  //function to combine the translation keys from language data and induction data
  export function combineTranslations(languages, inductionTranslations)
  {
    var newTransalation = {}
    for(var i = 0; i < languages.length; i++)
    {
        if(inductionTranslations)
        {
           var parsed = {};
           try{
             parsed = JSON.parse(inductionTranslations);
             //console.log('parsed',parsed);
           }
           catch(err) {
            console.log('cannot parse json');
          }

           if(parsed[languages[i].code])
              newTransalation[languages[i].code] = {...JSON.parse(languages[i]?.data),...parsed[languages[i].code]}
        }
        else
          newTransalation[languages[i].code] = JSON.parse(languages[i]?.data);
    }

    return newTransalation;

  }


  //used for updating page data to reflect the current selected language translations
  export function refreshInductionTranslationData(pageData = null, selectedPagePreview, translations, selectedLanguage)
  {
      //update the title of the page
      var updatedPageData = {}
      
      if(pageData)
        updatedPageData = {...pageData};
      else  
        updatedPageData = {...selectedPagePreview};
  
      //change the title
      if(selectedPagePreview?.title_id)
         updatedPageData.title = translations[selectedLanguage.code][selectedPagePreview?.title_id];
  
      //change the subtitle
      if(selectedPagePreview?.data?.subtitle_id)
         updatedPageData.data = {...updatedPageData.data,subtitle: translations[selectedLanguage.code][selectedPagePreview?.data?.subtitle_id]};
      else
         updatedPageData.data = {...updatedPageData.data,subtitle: ''};
  
  
      //now the data based on page type
      if(updatedPageData?.type == 'post_login')
      {
          var questions = [...updatedPageData?.data?.questions];
  
          for(var i = 0; i < questions.length; i++)
          {
              if(questions[i].id)
              {
                  if(translations[selectedLanguage.code][questions[i].id])
                     questions[i] = {...questions[i],name: translations[selectedLanguage.code][questions[i].id]}
                  else
                     questions[i] = {...questions[i],name: ''}
              }
          }
  
          updatedPageData.data = {...updatedPageData.data,'questions': questions}
  
      }
      else if(updatedPageData?.type == 'introduction')
      {
  
        if(updatedPageData?.data?.content_id)
        {
            if(translations[selectedLanguage.code][updatedPageData?.data?.content_id])
               updatedPageData.data = {...updatedPageData.data, content: translations[selectedLanguage.code][updatedPageData?.data?.content_id]};
            else
               updatedPageData.data = {...updatedPageData.data, content: updatedPageData?.data?.content_id};
        }
  
        var recommendations = [...updatedPageData?.data?.recommendations];
    
        for(var i = 0; i < recommendations.length; i++)
        {
            if(recommendations[i].text_id)
            {
                if(translations[selectedLanguage.code][recommendations[i].text_id])
                  recommendations[i] = {...recommendations[i],text: translations[selectedLanguage.code][recommendations[i].text_id]}
                else
                recommendations[i] = {...recommendations[i],text: ''}
            }
        }
    
        updatedPageData.data = {...updatedPageData.data,'recommendations': recommendations}
    
      }
      else if(updatedPageData?.type == 'all-chapters-complete')
      {
        if(updatedPageData?.data?.content_id)
        {
           if(translations[selectedLanguage.code][updatedPageData?.data?.content_id])
              updatedPageData.data = {...updatedPageData.data, content: translations[selectedLanguage.code][updatedPageData?.data?.content_id]};
           else
              updatedPageData.data = {...updatedPageData.data, content: updatedPageData?.data?.content_id};
        }
  
        if(updatedPageData?.data?.subcontent_id)
        {
           if(translations[selectedLanguage.code][updatedPageData?.data?.subcontent_id])
              updatedPageData.data = {...updatedPageData.data, subcontent: translations[selectedLanguage.code][updatedPageData?.data?.subcontent_id]};
           else
              updatedPageData.data = {...updatedPageData.data, subcontent: updatedPageData?.data?.subcontent_id};
        }
      }
      else if(updatedPageData?.type == 'site-rules')
      { 
          
        var rules = [...updatedPageData?.data?.rules];
    
        for(var i = 0; i < rules.length; i++)
        {
            if(rules[i].content_id)
            {
                if(translations[selectedLanguage.code][rules[i].content_id])
                  rules[i] = {...rules[i],content: translations[selectedLanguage.code][rules[i].content_id]}
                else
                  rules[i] = {...rules[i],content: rules[i].content}
            }
        }
    
        updatedPageData.data = {...updatedPageData.data,'rules': rules}
      }
      else if(updatedPageData?.type == 'complete')
      {
        if(updatedPageData?.data?.content_id)
        {
            if(translations[selectedLanguage.code][updatedPageData?.data?.content_id])
              updatedPageData.data = {...updatedPageData.data, content: translations[selectedLanguage.code][updatedPageData?.data?.content_id]};
            else
              updatedPageData.data = {...updatedPageData.data, content: updatedPageData?.data?.content_id};
        }
      }

      //console.log('updatedPageData',updatedPageData);
   
  
     return updatedPageData;
  
  }

  //Translation helpers
  export function prepareTranslatedHotspots(hotspots, translations, selectedLanguage)
  {
     var newHotspots = [...hotspots];

     newHotspots = newHotspots.map((item) => { return {
      ...item,
      name: translations[selectedLanguage.code][item?.name_id] ? translations[selectedLanguage.code][item?.name_id]: '-',
      content: translations[selectedLanguage.code][item?.content_id] ? translations[selectedLanguage.code][item?.content_id] : '-'

     } } );

     return newHotspots;
  }

  export function prepareTranslatedFlipcards(cards, translations, selectedLanguage)
  {
    var newCards = [...cards];

    newCards = newCards.map((item) => { return {
      ...item,
      title: translations[selectedLanguage.code][item?.title_id],
      front_title: translations[selectedLanguage.code][item?.front_title_id],
      front: translations[selectedLanguage.code][item?.front_id],
      back_title: translations[selectedLanguage.code][item?.back_title_id],
      back: translations[selectedLanguage.code][item?.back_id]

     } } );

     return newCards;
  }

  export function prepareTranslatedCarousel(content, translations, selectedLanguage)
  {
    var newCards = [...content];

    newCards = newCards.map((item) => { return {
      ...item,
      title: translations[selectedLanguage.code][item?.title_id],
      content:  translations[selectedLanguage.code][item?.content_id] ? translations[selectedLanguage.code][item?.content_id] : '',

     } } );

     return newCards;
  }


  export function prepareTranslatedCardsort(content, translations, selectedLanguage)
  {
    var newCards = [...content];

    newCards = newCards.map((item) => { return {
      ...item,
      name: translations[selectedLanguage.code][item?.name_id] ? translations[selectedLanguage.code][item?.name_id] : '-',
      content:  translations[selectedLanguage.code][item?.content_id] ? translations[selectedLanguage.code][item?.content_id] : '-',
      categories: item?.categories.map((catItem) => { return {...catItem, name: translations[selectedLanguage.code][catItem?.name_id] ? translations[selectedLanguage.code][catItem?.name_id] : '-' }})

     } } );

     return newCards;
  }