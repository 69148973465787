import React, { useEffect, useRef, useState } from "react";
import classes from "./imageUpload.module.scss";
import { Tooltip } from "react-tooltip";
import Icon from "components/UI/IconComponent";
import cn from "classnames";
import { delay } from "utils/helper";
import { ClickOutside, useInterval, randomIntFromInterval } from "utils/helper";
import { isNil } from "lodash";
import PDFIcon from "../../../../../assets/supervisorIcons/pdf_button.png";
import PDFIconHover from "../../../../../assets/supervisorIcons/pdf_button_hover.png";

const ImageUpload = ({
  imageUrl,
  onLoad,
  isUploading,
  isError = false,
  isSuccess = false,
  type = 'Image',
  onDelete,
  description = type == 'Image' ? "Upload a photo from your device that represent the hazard context. Don't worry, we've got the web-friendly optimization covered." 
                                : "Upload a PDF from your device that represent the hazard context"
}) => {
  const imageUploadRef = useRef();

  const actionList = [
    {
      icon: "autorenew",
      text: "Change",
      action: (item) => {
        imageUploadRef?.current?.click();
        setActionOpen(false);
        setTooltipOpen(false);
      },
      color: "#6FCF97",
      textColor: "#28353C",
    },
    {
      icon: "delete",
      text: "Delete",
      action: (item) => {
        onDelete(imageUrl);
        setTimer(setShowRemoved);
        setActionOpen(false);
        setTooltipOpen(false);
      },
      color: "#e26c82",
      textColor: "#e26c82",
    },
  ];
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showRemoved, setShowRemoved] = useState(false);

  const [count, setCount] = useState(0);
  const [isHoverImage, setHoverImage] = useState(false);
  const [isActionOpen, setActionOpen] = useState(false);
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    if (isUploading) setCount(0);
  }, [isUploading]);

  const setTimer = async (actionFunction) => {
    setHoverImage(false);
    actionFunction(true);
    await delay(2000);
    actionFunction(false);
  };

  useEffect(() => {
    if (isSuccess) {
      setTimer(setShowSuccess);
    }

    console.log('image uploader isSuccess',isSuccess);
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setTimer(setShowError);
    }
  }, [isError]);

  useInterval(() => {
    if (isUploading && count < 80) {
      setCount(count + randomIntFromInterval(10, 20));
    }
  }, 500);

  return (
    <>
      <div
        tabindex="0"
        data-tooltip-id="uploadImage"
        onMouseOver={() => {
          setTooltipOpen(true);
        }}
        onMouseLeave={() => setTooltipOpen(false)}
        className={cn(
          classes.uploadImageWrapper,
          isUploading && classes.uploading,
          showError && classes.error,
          showSuccess && classes.success,
          showRemoved && classes.removed,
          imageUrl && classes.h250
        )}
        onClick={() => {
          if (!imageUrl) {
            imageUploadRef?.current?.click();
          }
        }}
      >
        {!showError && !showSuccess && !isUploading && imageUrl ? (
          <div
            className={classes.previewImageWrapper}
            onMouseEnter={() => setHoverImage(true)}
            onMouseLeave={() => setHoverImage(false)}
          >
            {isHoverImage && (
              <>
                <div className={classes.previewImageShadow} />
                <div
                  tabindex="1"
                  className={classes.changePictureButton}
                  onClick={() => {
                    imageUploadRef?.current?.click();
                    setTooltipOpen(false);
                  }}
                >
                  {type == 'PDF' && (
                    <>
                    <img src={PDFIcon} className={classes.PDFIcon} />
                    <img src={PDFIconHover} className={classes.PDFIconHover} />
                    </>
                  )}

                  {type == 'Image' && (
                    <>
                    <Icon icon="addPhoto" color="#BB6BD9" size="46px" />
                    <span>Change Picture</span>
                    </>
                  )}
                  
                </div>
              </>
            )}
            
            {type == 'Image' && (
              <img
                className={classes.previewImage}
                src={imageUrl}
                alt="preview "
              />
            )}

            {type == 'PDF' && (
              <p><iframe src={imageUrl} style={{ height: '248px' }}></iframe></p>
            )}
       
            <div className={classes.actionBox}>
              <div
                style={{
                  opacity: (isHoverImage || isActionOpen) && "1",
                }}
                className={classes.actionButton}
                onClick={() => {
                  setActionOpen(!isActionOpen);
                }}
              >
              
                <Icon icon="action" size={25} color="#6FCF97" />
                
                
              </div>
              {isActionOpen && (
                <ClickOutside
                  onClick={() => {
                    setActionOpen(false);
                  }}
                >
                  <div id="popover" className={classes.popOver}>
                    {actionList.map((listItem) => (
                      <div
                        className={classes.popOverItem}
                        onClick={() => listItem.action()}
                      >
                        <Icon
                          icon={listItem.icon}
                          size={20}
                          color={listItem.color}
                        />
                        <span style={{ color: listItem.textColor }}>
                          {listItem.text}
                        </span>
                      </div>
                    ))}
                  </div>
                </ClickOutside>
              )}
            </div>
          </div>
        ) : (
          <>
            {isUploading ? (
              <div className={classes.uploadingWrapper}>
                <div className={classes.progresWrapper}>
                  <div
                    style={{ width: `${count > 90 ? 99 : count}%` }}
                    className={classes.progressBar}
                  />
                </div>
                <div className={classes.progressPercentageWrapper}>
                  <span className={classes.progressPercentageText}>
                    {count}%
                  </span>
                </div>
                <span className={classes.uploadText}>
                  Uploading & processing...
                </span>
              </div>
            ) : (
              <>
                <Icon
                  className={classes.previewIcon}
                  icon={
                    showRemoved
                      ? "remove"
                      : showError
                      ? "error"
                      : showSuccess
                      ? "celebration"
                      : "addPhoto"
                  }
                  color="#BB6BD9"
                  size="46px"
                />
                <span>
                  {showRemoved
                    ? type == 'Image' ? "The picture has been removed." : "The PDF has been removed."
                    : showError
                    ? "The file couldn't be processed properly due to the poor internet connection. Make sure you have sufficient reception and try again."
                    : showSuccess
                    ? type == 'Image' ? "All went rather swimmingly. Your picture is about to be displayed." : "All went rather swimmingly."
                    : type  == 'Image' ? "Upload a picture from your device" : "Upload a PDF file from your device"}
                </span>
              </>
            )}
          </>
        )}
        <input
          className={classes.uploadInput}
          ref={imageUploadRef}
          type="file"
          name="myImage"
          onChange={(event) => {
            onLoad(event);
          }}
        />
      </div>

      <Tooltip
        className="react-tooltip-keepsafe"
        id="uploadImage"
        place="top"
        type="dark"
        effect="solid"
        isOpen={isTooltipOpen}
      >
        <span>
          {description}
        </span>
      </Tooltip>
    </>
  );
};

export default ImageUpload;
