import React from "react";
import Input from "../../../../../components/Supervisor/input";
import classes from "../editor.module.scss";

import Select from "react-select";

const EditorMain = ({
selectedPage,
updatePageData,
languageOptions,
setPreviewCounter,
setSelectedLanguage,
selectedLanguage,
updateTranslation,
setSelectedPagePreview,
selectedRule,
selectedSection,
setSelectedSection,
translations,
selectedPagePreview

}) => {


return <div>

{languageOptions && (
        <div className={classes.Container}>
            <label className={classes.BlockLabel}>
            Language
            </label>
            <Select
            styles={{
                control: () => ({
                width: "100%",
                height: 44,
                display: "flex",
                background: "#f8f8f8",
                paddingLeft: "10px",
                color: "#40507e",
                border: "1px solid #E0E0E0",
                boxSizing: "border-box",
                borderRadius: "6px",
                boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontWeight: "bold",
                }),
            }}
            placeholder={"Select Language"}
            onChange={(val) => {
                //setSelectedOption(val.value);
                setPreviewCounter(0);

                setSelectedLanguage({
                code: val.value,
                name: val.label,
                });
                
            }}
            tabSelectsValue={selectedLanguage}
            value={languageOptions.filter(
                (option) => option.value === selectedLanguage?.code
            )}
            options={languageOptions}
            isSearchable={false}
            />
        </div>
        )} 

    {!selectedSection.title && (
    <Input
        hasTooltip={true}
        order="column"
        value={selectedPage?.title_id ? translations[selectedLanguage.code][selectedPage?.title_id] ? translations[selectedLanguage.code][selectedPage?.title_id] : '' : '' }
        name={"Heading Text"}
        characterLimit={250}
        onChange={(e) => {
        var newPageUpdate = { ...selectedPagePreview }
        newPageUpdate.title = e.target.value;
        
        //don't update the real data only preview
        //setSelectedPage(newPageUpdate);
        setSelectedPagePreview(newPageUpdate);

        //and translation
        updateTranslation(selectedLanguage.code, selectedPage?.title_id, e.target.value);
        }}
        label={"Heading Text"}
        placeholder={"Heading Text"}
        error={""}
        tooltipText={
        "Heading text, also known as the page name"
        }
    />
    )}
    
    {!selectedSection.title && (
    <Input
        hasTooltip={true}
        order="column"
        value={selectedPage?.data?.subtitle_id ? translations[selectedLanguage.code][selectedPage?.data?.subtitle_id] ? translations[selectedLanguage.code][selectedPage?.data?.subtitle_id] : '' : ''}
        name={"Supporting Text"}
        characterLimit={250}
        onChange={(e) => {
        updatePageData({key:'subtitle',value:e.target.value},true); //only preview
        updateTranslation(selectedLanguage.code, selectedPage?.data?.subtitle_id, e.target.value);
        }}
        label={"Supporting Text"}
        placeholder={"Supporting Text"}
        error={""}
        tooltipText={
        "Supporting text, appears underneath the heading"
        }
    />
    )}



    {selectedRule.title && !selectedSection.title  && (
        <Input
        hasTooltip={true}
        order="column"
        value={selectedRule?.title_id ? translations[selectedLanguage.code][selectedRule?.title_id] ? translations[selectedLanguage.code][selectedRule?.title_id] : '' : ''}
        name={"Chapter Name"}
        characterLimit={250}
        onChange={(e) => {
            /*
            var newChapterUpdate = { ...selectedRule }
            newChapterUpdate.title = e.target.value;
            setSelectedRule(newChapterUpdate);
            */
            //update only the translation
            updateTranslation(selectedLanguage.code, selectedRule?.title_id, e.target.value);
        }}
        label={"Chapter Name"}
        placeholder={"Chapter Name"}
        error={""}
        tooltipText={
            "Chapter Name"
        }
        />
    )}

    {selectedSection.title && (
        <Input
        hasTooltip={true}
        order="column"
        value={selectedSection?.title_id ? translations[selectedLanguage.code][selectedSection?.title_id] ? translations[selectedLanguage.code][selectedSection?.title_id] : '' : ''}
        name={"Section Name"}
        characterLimit={250}
        onChange={(e) => {
            var newSectionUpdate = { ...selectedSection }
            newSectionUpdate.title = e.target.value;
            setSelectedSection(newSectionUpdate);
            updateTranslation(selectedLanguage.code, selectedSection?.title_id, e.target.value);
        }}
        label={"Section Name"}
        placeholder={"Section Name"}
        error={""}
        tooltipText={
            "Section Name"
        }
        />
    )}
                



  </div>;
};

export default EditorMain;